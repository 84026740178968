import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import VueRouterSitemap from 'vue-router-sitemap';
import router from './router'
import 'element-closest-polyfill';
import { install } from 'resize-observer';
import VScrollLock from 'v-scroll-lock'
import VueLazyload from 'vue-lazyload'
import VueHead from 'vue-head'


// import store from './store'
// import { VueExtendLayout, layout } from 'vue-extend-layout'

// console.log(layout);
// Vue.use(VueExtendLayout)

// const snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);


const prerenderEventName = 'render-event';

Vue.config.productionTip = false
Vue.use(VScrollLock)
Vue.use(VueHead)
install();

Vue.use(VueLazyload, {
	preLoad: 2,
});


// import MainLayout from './layouts/MainLayout.vue'
// import NewLayout from './layouts/NewLayout.vue'

// Vue.component('main-layout', MainLayout)
// Vue.component('new-layout', NewLayout)



new Vue({
	router,
	render: h => h(App),
	// layout
}).$mount('#app')