<template>
	<div id="app">
		<vue-extend-layouts />
	</div>
</template>

<script>
	import VueExtendLayouts from 'vue-extend-layout'

	export default {
		computed: {
			layout() {
				return this.$route.meta.layout;
			}
		},
		components: { VueExtendLayouts }
	}
</script>