module.exports = [
	{
		path: '/',
		name: 'home',
		meta: {
			layout: 'NewLayout',
			breadcrumb: 'Главная',
			showBreadcrumb: false,
			firstBlockBanner: true,
			breadcrumb: {
				label: "Главная",
			},
			sitemap: {
				priority: 1.0,
			}
		},
		siteMap: {
			label: 'Главная',
			position: 'left'
		},
		component: () => import('./views/Index.vue')
	},{
		path: '/uslugi/',
		name: 'uslugi',
		meta: {
			layout: 'NewLayout',
			template: 'light',
			h1: '<h1>Услуги</h1>',
			description: 'Артрокетс сочетает в себе полный цикл разработки от аналитики до запуска проекта. Мы осознанно отказались от других услуг в пользу того, что умеем делать лучше всего.',
			breadcrumb: {
				label: "Услуги",
				parent: 'home'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			position: 'left'
		},
		component: () => import('./views/uslugi/Uslugi.vue')
	},{
		path: '/uslugi/razrabotka/',
		name: 'razrabotka',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Создание сайтов.</h1><br class="hide600"> Артрокетс Development',
			description: 'Создаем сайты для решения бизнес задач: привлечение новых клиентов, увеличение заказов, повышение лояльности к бренду, эффективная коммуникация с клиентами.',
			mainTitleClass: 'margin2',
			breadcrumb: {
				label: "Создание сайтов",
				parent: 'uslugi'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'uslugi',
		},
		component: () => import('./views/uslugi/razrabotka/Razrabotka.vue'),
	},{
		path: '/uslugi/internet-magazin/',
		name: 'internet-magazin',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Разработка интернет-магазинов</h1>, в которых покупают',
			breadcrumb: {
				label: "Разработка интернет-магазинов ",
				parent: 'uslugi'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.8,
			}
		},
		siteMap: {
			parent: 'razrabotka',
		},
		component: () => import('./views/uslugi/internet-magazin/Internet_magazin.vue'),
	},{
		path: '/uslugi/korp-site/',
		name: 'korp-site',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Разработка корпоративных сайтов</h1><br class="hide600"> с умным дизайном',
			breadcrumb: {
				label: "Создание корпоративных сайтов",
				parent: 'uslugi'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.8,
			}
		},
		siteMap: {
			parent: 'razrabotka',
		},
		component: () => import('./views/uslugi/korp-site/Korp_site.vue'),
	},{
		path: '/uslugi/support/',
		name: 'support',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Техническая поддержка сайта.</h1><br class="hide600"> Артрокетс Support',
			description: 'Техподдержка сайтов на PHP, 1С-Битрикс, Wordpress и фреймворках.<br> Развиваем и дорабатываем интернет-ресурсы. Подключаемся на любом этапе развития сайта.',
			mainTitleClass: 'margin2',
			breadcrumb: {
				label: "Техническая поддержка",
				parent: 'uslugi'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'uslugi',
		},
		component: () => import('./views/uslugi/support/Support.vue'),
	},{
		path: '/uslugi/bitrix/',
		name: 'bitrix',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Создание сайтов на 1С-Битрикс</h1>',
			description: 'CMS №1 в России согласно рейтингу Рунета. На этой системе управления работают сайты Россгострах, МВидео, Все Инструменты, ВТБ и еще 275 000 проектов других компаний.',
			mainTitleClass: 'margin2',
			breadcrumb: {
				label: "Создание сайтов на 1С-Битрикс",
				parent: 'uslugi'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'uslugi',
		},
		component: () => import('./views/uslugi/bitrix/Bitrix.vue'),
	},{
		path: '/portfolio/',
		name: 'portfolio',
		meta: {
			layout: 'NewLayout',
			template: 'light',
			h1: '<h1>Проекты</h1>',
			breadcrumb: {
				label: "Проекты",
				parent: 'home'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			position: 'right'
		},
		component: () => import('./views/portfolio/Portfolio.vue')
	},{
		path: '/portfolio/citystar/',
		meta: {
			layout: 'MainLayout',
			noRouter: true,
			sitemap: {
				priority: 0.9,
			}
		}
	},{
		path: '/portfolio/doghelp/',
		meta: {
			layout: 'MainLayout',
			noRouter: true,
			sitemap: {
				priority: 0.9,
			}
		}
	},{
		path: '/portfolio/rgs-oms/',
		meta: {
			layout: 'MainLayout',
			noRouter: true,
			sitemap: {
				priority: 0.9,
			}
		}
	},{
		path: '/portfolio/savage/',
		meta: {
			layout: 'MainLayout',
			noRouter: true,
			sitemap: {
				priority: 0.9,
			}
		}
	},{
		path: '/portfolio/dymov/',
		meta: {
			layout: 'NewLayout',
			noRouter: true,
			firstBlockBanner: true,
			casePage: true,
			caseName: 'dymov',
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/dymov/Dymov.vue')
	},{
		path: '/portfolio/medar/',
		name: 'medar',
		meta: {
			layout: 'NewLayout',
			template: 'light',
			showBreadcrumb: false,
			firstBlockBanner: true,
			oldTemplate: true,
			transitionName: "fade2",
			topMenuClass: "blackLinks",
			casePage: true,
			caseName: 'medar',
			breadcrumb: {
				label: "Medar Beauty",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/medar/Medar.vue')
	},{
		path: '/portfolio/centrserdce/',
		name: 'centrserdce',
		meta: {
			layout: 'NewLayout',
			template: 'whiteBack',
			showBreadcrumb: false,
			firstBlockBanner: true,
			oldTemplate: true,
			transitionName: "fade2",
			topMenuClass: "blackLinks",
			casePage: true,
			caseName: 'centrserdce',
			breadcrumb: {
				label: "Центр Сердце",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/centrserdce/Centrserdce.vue')
	},{
		path: '/portfolio/abd/',
		name: 'abd',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			oldTemplate: true,
			casePage: true,
			caseName: 'abd',
			breadcrumb: {
				label: "Ак Барс дом",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/abd/Abd.vue')
	},{
		path: '/portfolio/kaplife/',
		name: 'kaplife',
		meta: {
			layout: 'NewLayout',
			template: 'light kaplifeTemplate',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			topMenuClass: "blackLinks",
			oldTemplate: true,
			casePage: true,
			caseName: 'kaplife',
			breadcrumb: {
				label: "КАПИТАЛ LIFE",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/kaplife/Kaplife.vue')
	},{
		path: '/portfolio/kk/',
		name: 'kk',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			casePage: true,
			caseName: 'kk',
			breadcrumb: {
				label: "Кабинет клиента КАПИТАЛ Life",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/kk/Kk.vue')
	},{
		path: '/portfolio/technorosst/',
		name: 'technorosst',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			casePage: true,
			caseName: 'technorosst',
			breadcrumb: {
				label: "Техноросст",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/technorosst/Technorosst.vue')
	},{
		path: '/portfolio/packs/',
		name: 'packs',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			casePage: true,
			caseName: 'packs',
			breadcrumb: {
				label: "Мир Упаковки",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/packs/Packs.vue')
	},
	{
		path: '/portfolio/tsi/',
		name: 'tsi',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			casePage: true,
			caseName: 'tsi',
			breadcrumb: {
				label: "ТСИ",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/tsi/Tsi.vue')
	},
	{
		path: '/portfolio/mpkabel/',
		name: 'mpkabel',
		meta: {
			layout: 'NewLayout',
			showBreadcrumb: false,
			firstBlockBanner: true,
			transitionName: "fade2",
			casePage: true,
			caseName: 'mpkabel',
			breadcrumb: {
				label: "Марпосадкабель",
				parent: 'portfolio'
			},
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			parent: 'portfolio'
		},
		component: () => import('./views/portfolio/mpkabel/Mpkabel.vue')
	},{
		path: '/vacancies/',
		name: 'vacancies',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Вакансии.</h1> Артрокетс в поиске новых талантов в веб-индустрии',
			description: 'Добро пожаловать в команду «строителей», создающих компанию мечты.',
			breadcrumb: {
				label: "Вакансии",
				parent: 'home'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			position: 'right'
		},
		component: () => import('./views/vacancies/Vacancies.vue')
	},{
		path: '/contacts/',
		name: 'contacts',
		meta: {
			layout: 'MainLayout',
			template: 'light',
			h1: '<h1>Контакты</h1>',
			breadcrumb: {
				label: "Контакты",
				parent: 'home'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			position: 'right'
		},
		component: () => import('./views/contacts/Contacts.vue')
	},{
		path: '/about/',
		name: 'about',
		meta: {
			layout: 'NewLayout',
			template: 'light',
			h1: '<h1>О студии</h1>',
			mainTitleClass: 'margin2',
			breadcrumb: {
				label: "О компании",
				parent: 'home'
			},
			showBreadcrumb: true,
			sitemap: {
				priority: 0.9,
			}
		},
		siteMap: {
			position: 'right'
		},
		component: () => import('./views/about/About.vue')
	},{
		path: '/404/',
		name: 'page404',
		meta: {
			layout: 'MainLayout',
			firstBlockBanner: true,
			sitemap: {
				ignoreRoute: true
			}
		},
		component: () => import('./views/NotFound.vue')
	}, { 
		path: '*',
		name: 'page404',
		meta: {
			layout: 'MainLayout',
			firstBlockBanner: true,
			sitemap: {
				ignoreRoute: true
			}
		},
		component: () => import('./views/NotFound.vue')
	}
]