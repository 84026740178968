import Vue from 'vue'
import VueRouter from 'vue-router'
import Vue2Crumbs from 'vue-2-crumbs'
import Meta from 'vue-meta'

const routes = require('../routes');

Vue.use(VueRouter)
Vue.use(Vue2Crumbs)
Vue.use(Meta, {
	keyName: 'metaInfo',
	attribute: 'data-vue-meta',
	ssrAttribute: 'data-vue-meta-server-rendered',
	tagIDKeyName: 'vmid',
	refreshOnceOnNavigation: true,
})



const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if(from.meta.layout && to.meta.layout && to.meta.layout != from.meta.layout){
		window.location.href = to.fullPath;
	} else{
		next();
	}
})

router.afterEach(function (transition) {
	setTimeout(function() {
		doScrolling(0, 400);
	}, 500);
});

export default router
